'use strict'

###*
 # @ngdoc object
 # @name mundoReporting.service:ReportSchedulingService

 # @description

###
class ReportSchedulingService
  ### @ngInject ###
  constructor:(
    $log
    $filter
    $q
    ReportConfigurationManager
    ReportConfigurationService
    UserPermissions
    UiHelpers
    ReportTransportManager
    $mdConstant
    _
  ) ->

    @selectScheduling = (src, cfg) ->

      schedulers = angular.copy cfg.schedulers
      transports = []
      visualizers = if src.visualizers? then _.values(src.visualizers) else {}
      schedules = [
          code: '0 0 * * *'
          label: 'daily'
        ,
          code: '0 0 * * 0'
          label: 'weekly'
        ,
          code: '0 0 1 * *'
          label: 'monthly'
      ]

      data =
        schedules: schedules
        transports: transports
        visualizers: visualizers
        originalSchedulers: angular.copy schedulers
        schedulers: schedulers
        emailSeparatorKeys: [
          $mdConstant.KEY_CODE.ENTER
          $mdConstant.KEY_CODE.COMMA
          $mdConstant.KEY_CODE.TAB
          $mdConstant.KEY_CODE.SPACE
          $mdConstant.KEY_CODE.SEMICOLON
        ]

      ReportTransportManager.getList().then (results) ->
        (data.transports.push x for x in results)

      data.add = () ->
        scheduler =
          visualizers: []
          transport: if data.transports.length then data.transports[0].code else null
          schedule: if data.schedules.length then data.schedules[0].code else null
          parameters: {}

        data.schedulers.push scheduler
        data.onSchedulerTransportChange scheduler

      data.undo = () ->
        data.schedulers = angular.copy data.originalSchedulers

      data.remove = (key) ->
        data.schedulers.splice(key, 1)

      data.clear = () ->
        data.schedulers.splice(0, data.schedulers.length)

      data.toggleSchedulerVisualizer = (scheduler, visualizer) ->
        index = scheduler.visualizers.indexOf(visualizer.code)
        if index > -1
          scheduler.visualizers.splice index, 1
        else
          scheduler.visualizers.push visualizer.code

      data.onSchedulerTransportChange = (scheduler) ->
        if not scheduler.transport
          scheduler.parameters = {}
          return

        transport = data.transports.filter (v) -> v.code == scheduler.transport
        transport = transport[0]

        for k, v of scheduler.parameters
          if k not in Object.keys(transport.parameters)
            delete scheduler.parameters[k]

        for k, v of transport.parameters
          if k not in Object.keys(scheduler.parameters)
            if v.type? and v.type == 'collection'
              scheduler.parameters[k] = []
            else if v.multiple? and v.multiple
              scheduler.parameters[k] = []
            else
              scheduler.parameters[k] = null

      data.validateScheduler = (scheduler) ->
        if not scheduler.transport
          return false

        if not scheduler.schedule
          return false

        if not (scheduler.visualizers? and scheduler.visualizers.length)
          return false

        if not (scheduler.parameters?)
          return false

        if scheduler.transport == 'Email'
          if not (scheduler.parameters.recipients? and scheduler.parameters.recipients.length)
            return false

        return true

      return $q (resolve, reject) ->
        UiHelpers.openDialog
          template: 'mundo-reporting/views/dialogs/report-scheduling-dialog.tpl.html'
          title: 'app.report.scheduling'
          data: data
          onConfirm: (result) ->
            promises = []

            for scheduler in data.schedulers
              if not data.validateScheduler scheduler
                continue

              if not scheduler._id
                promises.push ReportConfigurationService.createScheduler(cfg, scheduler)

              else
                changes =
                  parameters: scheduler.parameters
                  visualizers: scheduler.visualizers
                  schedule: scheduler.schedule
                  transport: scheduler.transport
                promises.push ReportConfigurationService.patchScheduler(cfg, scheduler._id, changes)

            for originalScheduler in data.originalSchedulers
              scheduler = data.schedulers.filter (v) -> v._id == originalScheduler._id
              scheduler = if scheduler.length then scheduler[0] else null

              if not scheduler
                promises.push ReportConfigurationService.removeScheduler(cfg, originalScheduler._id)

            $q.all(promises).then (results) ->
              ReportConfigurationService
                .getReportConfiguration cfg._id
                .then (res) ->
                  schedulers = res.schedulers
                  cfg.schedulers = schedulers
                  resolve(schedulers)
          onCancel: () ->
            reject()


angular
  .module('mundoReporting')
  .service 'ReportSchedulingService', ReportSchedulingService
